








































import {
  Component, Vue,
} from 'vue-property-decorator';
import AllianzDepositFlexibleResultViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/flexible_deposits/allianz-deposit-flexible-result-view-model';

@Component({
  name: 'FlexibleDepositResult',
})
export default class FlexibleDepositResult extends Vue {
  allianz_deposit_flexible_result_view_model = Vue.observable(
    new AllianzDepositFlexibleResultViewModel(this),
  );
}

